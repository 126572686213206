import { useEffect, useState } from "react";

import {
  BsFillPersonFill,
  BsFillEnvelopeFill,
  BsTelephoneFill,
  BsFillCalendarFill,
  BsFillHandbagFill,
} from "react-icons/bs";
import { BiPackage } from "react-icons/bi";
import {
  FaBarcode,
  FaMoneyBillAlt,
  FaScroll,
  FaTruckMoving,
  FaPlaneDeparture,
} from "react-icons/fa";
import { GiWeight } from "react-icons/gi";

const UserStatisticCard = ({ data }) => {
  return (
    <div className="bg-white p-4 rounded-lg dark:bg-slate-800">
      <div className="w-full flex flex-wrap justify-between pb-2 gap-y-2">
        <div className="w-[32%] md:w-[49%] sm:w-[100%] flex flex-col gap-y-1">
          <div className="w-full">
            <p className="text-[#989ba5] text-[12px]">
              მომხმარებელის ინფორმაცია
            </p>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsFillPersonFill className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">{`${data.fname} ${data.lname}`}</p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaBarcode className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">{`id: ${data.id}`}</p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsFillEnvelopeFill className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                {data.email}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsTelephoneFill className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                {data.phone}
              </p>
            </div>
          </div>
        </div>
        <div className="w-[32%] md:w-[49%] sm:w-[100%] flex flex-col gap-y-1">
          <div className="w-full">
            <p className="text-[#989ba5] text-[12px]">გზავნილის ინფორმაცია</p>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BiPackage className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                გზავნილის ID: {data.tracking_number}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaMoneyBillAlt className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                ტრანსპორტირების ფასი:{" "}
                {data.pack_sum && data.pack_sum.toFixed(2)}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <GiWeight className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                წონა: {data.total_weight}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsFillCalendarFill className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                თარიღი: {data.last_update.split(" ")[0]}
              </p>
            </div>
          </div>
        </div>
        <div className="w-[32%] md:w-[49%] sm:w-[100%] flex flex-col gap-y-1">
          <div className="w-full">
            <p className="text-[#989ba5] text-[12px]">გზავნილის ინფორმაცია</p>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaScroll className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p
                className={`text-[14px] ${
                  data.addon_transportation ? "text-green-500" : "text-red-500"
                } `}
              >
                დაზღვევა: {data.addon_transportation ? "დაზღვეულია" : "X"}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaTruckMoving className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p
                className={`text-[14px] ${
                  data.addon_transportation ? "text-green-500" : "text-red-500"
                } `}
              >
                საკურიერო: {data.addon_transportation ? "Yes" : "X"}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaScroll className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p
                className={`text-[14px] text-violet-500 underline cursor-pointer`}
              >
                ინვოისი
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsFillHandbagFill className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p
                className={`text-[14px] text-slate-500 dark:text-gray-400 cursor-pointer font-bold`}
              >
                ჩანთა: {data.bag}
              </p>
            </div>
          </div>
        </div>
        <div className="w-[32%] md:w-[49%] sm:w-[100%] flex flex-col gap-y-1">
          <div className="flex items-center gap-1 my-1">
            <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
              <FaPlaneDeparture className="w-full h-full text-slate-500 dark:text-gray-400" />
            </div>
            <p
              className={`text-[14px] text-slate-500 dark:text-gray-400 cursor-pointer font-bold`}
            >
              რეისი: {data.flight_number}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserStatisticCard;
