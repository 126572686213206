import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { successToast, errorToast } from "../../../toastAlerts/ToastAlerts";
import { AdminPrimaryContext } from "../../../adminContext/AdminContext";
import SentItem from "./SentItem";
import {
  CheckBox,
  SelectInputValueId,
} from "../../../inputComponents/inputcomp";
import axiosAdmin from "../../../../../api/apiAdmin";
import PaginatedItems from "../../../Pagination/Pagination";
import { BsX } from "react-icons/bs";

//icons
import { FaRegHandPointRight } from "react-icons//fa";

const SentPackage = () => {
  //current filial
  const [currentPlaceId, setCurrentPlaceId] = useState(1);
  //branch data
  const { user, globalSearchValue, handleSearchValue, handleSearchTransport } =
    useContext(AdminPrimaryContext);
  //package data
  const [packageData, setPackageData] = useState([]);
  //package dataErr
  const [packageDataErr, setPackageDataErr] = useState([]);
  //affect data change depended on delete and add responses
  const [difResponses, setDifResponses] = useState(null);
  //package check all
  const [checkAll, setCheckAll] = useState(false);
  //place in warehouse
  const [shelf, setShelf] = useState({
    x: "",
    y: "",
  });
  //selected Packages
  const [selectedPackages, setSelectedPackages] = useState({
    pack_id: [],
  });
  //current page
  const [currentPage, setCurrentPage] = useState(0);
  //total records
  const [total, setTotal] = useState(0);
  //get filial data from api
  const [filialData, setFilialData] = useState([]);
  //how many parcel does this user have on this flight number
  const [userPacksCount, setUserPackCount] = useState(0);
  //ab offices with weight and parcel quantity
  const [foreignAddresses, setForeignAddresses] = useState([]);

  //handle shelf value
  const handleShelf = (e, inputName) => {
    setShelf((prev) => ({ ...prev, [inputName]: e.target.value }));
  };

  //handle shelf2 value
  const handleShelf2 = (value, inputName) => {
    setShelf((prev) => ({ ...prev, [inputName]: value }));
  };

  //handle selected Packages
  const handleSelectedPackages = (value) => {
    if (!selectedPackages.pack_id.includes(value)) {
      setSelectedPackages((prev) => ({ pack_id: [...prev.pack_id, value] }));
    }
  };
  //handle delete selected Packages
  const handleDeleteSelectedPackages = (value) => {
    setSelectedPackages((prev) => ({
      pack_id: prev.pack_id.filter((obj) => {
        return obj !== value;
      }),
    }));
  };

  //handle checking
  const handleCheckAll = (e) => {
    setCheckAll(e.target.checked);
  };

  //current filial handler
  const handleCurrentPlaceId = (value) => {
    setCurrentPlaceId(value);
    setCheckAll(false);
    setSelectedPackages({
      pack_id: [],
    });
  };

  //handle delete package
  const handleDeletePackage = async (id) => {
    try {
      const res = await axiosAdmin.get(`/Pack/delete/${id}`);
      if (res) {
        setDifResponses(res);
        successToast("ამანათი წარმატებით წაიშალა!");
      }
    } catch (err) {
      setPackageDataErr(err);
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  //const handle send package
  const handleSendAllPackages = async () => {
    if (shelf.x === "" || shelf.y === "") {
      errorToast("გთხოვთ შეავსოთ თაროების ველი!");
    } else {
      try {
        const res = await axiosAdmin.post(`/Pack/set_receive_geo`, {
          ...selectedPackages,
          ...shelf,
        });
        if (res) {
          successToast("ამანათები წარმატებით მიიღე!");
          setCheckAll(false);
          setSelectedPackages({
            pack_id: [],
          });
          setCurrentPage(0);
          setDifResponses(res);
        }
      } catch (err) {
        errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
      }
    }
  };

  //parcel function
  const handleParcelThings = () => {
    axiosAdmin
      .get(
        `/Pack/get/3/${currentPlaceId}/30/${currentPage + 1}?key=${
          globalSearchValue.key
        }&transport=${globalSearchValue.transport}`
      )
      .then((res) => {
        setPackageData(res.data.packs.data);
        setTotal(res.data.packs.total);

        setUserPackCount(res.data.userPackCount);
      })
      .catch((err) => {
        setPackageDataErr(err);
      });
  };

  //show parcels from every ab_office
  const handleShowAllPackage = () => {
    setCurrentPlaceId(0);
  };

  //package data fetching
  useEffect(() => {
    handleParcelThings();
  }, [currentPlaceId, difResponses, globalSearchValue, currentPage]);

  //on barcode scanner using
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleParcelThings();
    }
  };

  useEffect(() => {
    if (selectedPackages.pack_id.length > 0) {
      setCheckAll(false);
      setSelectedPackages({
        pack_id: [],
      });
    }
  }, [currentPage]);

  //move parcel to previous status
  const handleMoveBack = (parcelId) => {
    axiosAdmin
      .get(`/Pack/back/${parcelId}`)
      .then((res) => {
        setDifResponses(res);
      })
      .catch((err) => {
        setPackageDataErr(err);
      });
  };

  //filial data from api
  useEffect(() => {
    try {
      axiosAdmin.get(`/Branches/index`).then(({ data }) => {
        setFilialData(
          data.map((item) => {
            return {
              name: item.address,
              value: item.id,
            };
          })
        );
      });
    } catch (error) {
      setPackageDataErr(error);
    }
  }, []);

  //auto shelf x
  useEffect(() => {
    if (user && user.user) {
      const tmp = user.user.split("-");
      if (tmp.length !== 0 && tmp[1]) {
        setShelf((prev) => ({ ...prev, x: tmp[1] }));
      }
    }
  }, [user]);

  //on page load
  useEffect(() => {
    axiosAdmin
      .get(`/Pack/officeInfo/3`)
      .then((res) => {
        setForeignAddresses(res.data);
      })
      .catch((err) => {
        setPackageDataErr(err);
      });
  }, []);

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24 relative"
    >
      <div
        className="fixed top-14 left-[40%] md:left-[50%] md:translate-x-[-50%] sm:left-[50%] sm:translate-x-[-50%] lg:translate-x-[-50%] lg:left-[50%]
             bg-white dark:bg-slate-900 p-2 z-40 gap-2 shadow-sm border-gray-300 border
            border-t-0 rounded-b-lg grid grid-cols-3"
      >
        <input
          type="text"
          className="h-10 rounded-lg shadow-sm border-gray-300 border dark:bg-slate-700 
                text-gray-400 pl-2 w-full"
          placeholder="x"
          onChange={(e) => {
            handleShelf(e, "x");
          }}
          value={shelf.x}
        />
        <input
          type="text"
          className="h-10 rounded-lg shadow-sm border-gray-300 border dark:bg-slate-700 
                text-gray-400 pl-2 w-full"
          placeholder="y"
          onChange={(e) => {
            handleShelf(e, "y");
          }}
          value={shelf.y}
        />
        <SelectInputValueId
          options={filialData}
          inputName={"in_office"}
          inputSetter={handleShelf2}
        />
      </div>
      <div className=" mb-5 p-2 overflow-x-auto scrollbar relative mt-[55px]">
        <div className="min-w-[1000px] w-full flex gap-4">
          {foreignAddresses.map((item) => {
            return (
              <div
                key={item.id}
                className={`w-full flex items-center p-3 rounded-lg gap-5 cursor-pointer
                    shadow-[rgba(50,50,93,0.25)_0px_2px_5px_-1px,rgba(0,0,0,0.3)_0px_1px_3px_-1px] 
                    ${
                      currentPlaceId === item.id
                        ? "border border-green-500 bg-green-100"
                        : "bg-white dark:bg-slate-700 "
                    }`}
              >
                <div className="w-full flex flex-col gap-2 justify-between h-full">
                  <p className="text-[14px] text-[#8e95a8]">{item.title}</p>
                  <p className="text-[14px] text-[#8e95a8]">
                    {`${item.country} ${item.city}`}
                  </p>
                  <p className="text-[14px] text-[#8e95a8]">{item.address}</p>
                  <p className="text-[14px] text-[#8e95a8]">{item.phone}</p>
                  <p className="text-[14px] text-[#8e95a8]">
                    {`${item.pack_count}`} parcel /{" "}
                    {`${item.total_weight.toFixed(2)}`} kg
                  </p>
                  <div className="flex gap-1">
                    <div
                      onClick={() => {
                        handleCurrentPlaceId(item.id);
                      }}
                      className="w-full rounded-lg bg-green-400
                 flex items-center justify-center gap-1 p-1 py-3 text-white"
                    >
                      <FaRegHandPointRight />
                      <p>აირჩიე</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div
          className="absolute top-0 right-0 bg-red-500 w-8 h-8 rounded-full p-1 cursor-pointer"
          onClick={handleShowAllPackage}
        >
          <BsX className="w-full h-full text-white" />
        </div>
      </div>
      <h2 className="text-slate-400 text-2xl">გამოგზავნილი ამანათები</h2>
      <div className="w-full">
        <div className="bg-yellow mt-2 flex gap-1 sm:flex-col">
          <div
            className="bg-white  pv-2 flex items-center hover:outline-slate-400 hover:outline-2
            hover:outline gap-x-1  px-3.5 py-1 rounded-lg dark:bg-slate-800 border-gray-300
            border dark:border-slate-600 w-9/12 sm:w-full"
          >
            <input
              type="search"
              placeholder="ძებნა"
              value={globalSearchValue.key}
              onChange={(e) => {
                handleSearchValue(e, "key");
              }}
              className="w-full h-8 pl-1.5 focus:outline-none dark:text-white
                                dark:bg-slate-800 border-0"
              onKeyDown={handleKeyPress}
            />
          </div>
          {(user.role === 100 || user.role === 1) && (
            <Link
              to={`${
                process.env.REACT_APP_API_URL
              }/api/admin/Pack/export/3/${currentPlaceId}/30/0?key=${
                globalSearchValue.key ? parseInt(globalSearchValue.key) : ""
              }`}
              className="w-3/12 rounded-lg bg-green-400 sm:w-full
                 flex items-center justify-center p-1 py-3 text-white"
            >
              EXPORT CSV
            </Link>
          )}
        </div>
        <div className="flex items-center justify-between my-2 flex-wrap">
          <div className="flex gap-2 items-center sm:order-2 flex-wrap">
            <CheckBox
              name={"საკურიერო"}
              inputName={"transport"}
              handleCarData={handleSearchTransport}
            />
            {(user.role === 100 ||
              user.role === 1 ||
              user.role === 5 ||
              user.role === 4 ||
              user.role === 8) && (
              <div className="flex items-center gap-3">
                <input
                  checked={checkAll}
                  onChange={(e) => {
                    handleCheckAll(e);
                  }}
                  type="checkbox"
                />
                <p className="text-sm text-gray-500">ყველას მონიშვნა</p>
              </div>
            )}
            <p className="text-sm text-violet-500">
              მომხმარებელს ამავე რესიზე აქვს : {userPacksCount} ამანათი
            </p>
          </div>
          {/* {(user.role !== 6) &&
                        <div className="flex gap-2 sm:w-full">
                            <div
                                onClick={handleSendAllPackages}
                                className="rounded-lg bg-green-400 cursor-pointer
flex items-center justify-center gap-1 p-1 py-3 text-white sm:w-full">
                                <p>მონიშნულის მიღება</p>
                            </div>
                        </div>
                    } */}
        </div>
        <div className="w-full flex gap-3 flex-wrap mt-5">
          <PaginatedItems pageNumberSetter={setCurrentPage} dataLength={total}>
            <div className="w-full flex flex-col gap-y-2">
              {packageData.map((item) => {
                return (
                  <SentItem
                    key={item.id}
                    data={item}
                    handleDeletePackage={handleDeletePackage}
                    setCheckedAll={checkAll}
                    handleSelectedPackages={handleSelectedPackages}
                    handleDeleteSelectedPackages={handleDeleteSelectedPackages}
                    setDifResponses={setDifResponses}
                    shelf={shelf}
                    handleMoveBack={handleMoveBack}
                  />
                );
              })}
            </div>
          </PaginatedItems>
        </div>
      </div>
    </div>
  );
};

export default SentPackage;
