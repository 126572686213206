import chinaImg from "../img/countryFlags/china.png";
import turkeyImg from "../img/countryFlags/turkey.png";
import usaImg from "../img/countryFlags/usa.png";
import greeceImg from "../img/countryFlags/greece.png";
import ukImg from "../img/countryFlags/uk.png";

export const specPriceData = [
    {
        image: turkeyImg,
        country: "Turkey",
        price: "$ 3",
        type: "land",
    },
    {
        image: chinaImg,
        country: "China",
        price: "$ 11.9",
        type: "air",
    },
    {
        image: usaImg,
        country: "USA",
        price: "$ 8",
        type: "air",
    },
    {
        image: chinaImg,
        country: "China",
        price: "$ 4.9",
        type: "land",
    },
    {
        image: greeceImg,
        country: "Greece",
        price: "€ 3",
        type: "land",
    },

    {
        image: ukImg,
        country: "England",
        price: "£ 6.7",
        type: "air",
    },
]