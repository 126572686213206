import { useState, useEffect, useContext } from "react";
import axiosClient from "../../api/api";
import { Text } from "../../components/Text";
import { List } from "../../components/List";
import { Img } from "../../components/Img";
import PanelHead from "../../components/PanelHead/PanelHead";
import PaneladdressesturkeyPageCard from "./PaneladdressesturkeyPageCard";
import { PrimmaryContext } from "../../components/MainContext/MainContext";
import { useTranslation } from "react-i18next";

//images
import chinaImg from "../../img/countryFlags/china.png";
import turkeyImg from "../../img/countryFlags/turkey.png";
import usaImg from "../../img/countryFlags/usa.png";
import greeceImg from "../../img/countryFlags/greece.png";
import ukImg from "../../img/countryFlags/uk.png";

const PaneladdressesPage = () => {
  //translations
  const { t } = useTranslation();
  //abroad office data
  const [abOffices, setAbOffices] = useState([
    {
      id: 1,
      name: "turkey",
      img: turkeyImg,
    },
    {
      id: 5,
      name: "china",
      img: chinaImg,
    },
    {
      id: 16,
      name: "usa",
      img: usaImg,
    },
    {
      id: 19,
      name: "china_land",
      img: chinaImg,
    },
    {
      id: 20,
      name: "greece",
      img: greeceImg,
    },
    {
      id: 21,
      name: "england",
      img: ukImg,
    },
  ]);
  //current country
  const [currentCountry, setCurrentCountry] = useState(1);
  //branches data
  const [branches, setBranches] = useState([]);
  //handle current ccountry
  const handleChangeCountry = (coutryName) => {
    setCurrentCountry(coutryName);
  };
  //error
  const [error, setError] = useState(null);
  //data from api
  const { user, prices } = useContext(PrimmaryContext);

  //get branches data
  const handleGetBraanches = async () => {
    try {
      const res = await axiosClient.get(`/Branches/index/${currentCountry}`);
      if (res) {
        setBranches(res.data);
      }
    } catch (err) {
      setError(err);
    }
  };

  //transactions data api
  useEffect(() => {
    handleGetBraanches();
  }, [currentCountry]);

  return (
    <div
      className="w-full flex flex-col font-firago gap-9 max-w-[1500px] px-11 py-8  md:px-5 sm:px-5
    sm:pb-[80px] md:pb-[80px]"
    >
      <PanelHead name={t("addresses")} />
      <div className="font-notosansgeorgian relative w-[100%]">
        <div
          className="flex flex-col gap-[40px] inset-[0] items-start justify-start m-[auto] max-w-[1408px] w-[100%]
          sm:gap-8 md:gap-8"
        >
          <Text
            className="font-semibold text-black_900_e0 text-left tracking-ls048 md:tracking-ls1 sm:tracking-ls1 w-[auto]
          hidden sm:block md:block dark:text-[#c7c7c7]"
            variant="body1"
          >
            {t("addresses")}
          </Text>
          <div
            className="bg-gray_600_29 w-[auto] p-[2px] rounded-radius10  sm:w-full
             flex items-center flex-wrap dark:bg-[#171719]"
          >
            {abOffices.map((office, index) => {
              return (
                <div
                  key={office.id}
                  onClick={() => handleChangeCountry(office.id)}
                  className={`flex gap-[12px] items-center px-4 py-2
                sm:w-full sm_to_lg:w-[50%] rounded-lg cursor-pointer ${
                  currentCountry === office.id && "bg-white dark:bg-[#48484a]"
                }`}
                >
                  <div className="h-8">
                    <Img
                      src={office.img}
                      className="common-pointer h-full w-full object-contain rounded-lg"
                      alt={office.name}
                    />
                  </div>
                  <Text
                    className="font-normal not-italic text-black_900_b7 text-left w-[auto]"
                    variant="body7"
                  >
                    <span className="text-black_900_b7 text-sm sm:text-xs font-notosansgeorgian dark:text-[#c7c7c7]">
                      {t(office.name)}{" "}
                    </span>
                    <span className="text-indigo_400 text-sm font-notosansgeorgian">
                      {office.id === 20 ? "€" : office.id === 21 ? "£" : "$"}
                      {prices.length !== 0 && prices[index]?.price}
                    </span>
                  </Text>
                </div>
              );
            })}
          </div>

          <div className="grid grid-cols-1 gap-4 w-full sm:gap-2">
            {branches.user && (
              <>
                <PaneladdressesturkeyPageCard
                  title={"სახელი / Name"}
                  value={
                    (currentCountry === 19 ||
                    currentCountry === 5 ||
                    currentCountry === 16
                      ? `EG${user.id} `
                      : "") +
                    (currentCountry === 19 ? "Land " : "") +
                    (branches.user.user_type !== 2
                      ? branches.user.fname_eng
                      : branches.user.company_name)
                  }
                />
                <PaneladdressesturkeyPageCard
                  title={"გვარი / Surname"}
                  value={
                    branches.user.user_type !== 2
                      ? branches.user.lname_eng
                      : branches.user.company_name_eng
                  }
                />
              </>
            )}
            <PaneladdressesturkeyPageCard
              title={"ქვეყანა / Country "}
              value={branches.offices && branches.offices.country}
            />
            <PaneladdressesturkeyPageCard
              title={
                currentCountry !== 16 ? "პროვინცია / Province" : "შტატი / State"
              }
              value={branches.offices && branches.offices.city}
            />
            <PaneladdressesturkeyPageCard
              title={
                currentCountry !== 16 && currentCountry !== 5
                  ? "რაიონი / District "
                  : "ქალაქი / City"
              }
              value={branches.offices && branches.offices.district}
            />
            {currentCountry !== 16 && (
              <PaneladdressesturkeyPageCard
                title={"სამეზობლო / Neighborhood"}
                value={branches.offices && branches.offices.neighborhood}
              />
            )}
            <PaneladdressesturkeyPageCard
              title={"მისამართი 1 / Address 1"}
              value={
                branches.offices &&
                `${currentCountry !== 16 ? `EG${user.id}` : ""} ${
                  branches.offices.address
                } ${currentCountry !== 16 ? `EG${user.id}` : ""}`
              }
            />
            <PaneladdressesturkeyPageCard
              title={"მისამართი 2 / Address 2"}
              value={
                branches.offices &&
                `EG${branches.user !== null && branches.user.id}`
              }
            />
            <PaneladdressesturkeyPageCard
              title={"ტელეფონი / Phone"}
              value={branches.offices && branches.offices.phone}
            />
            <PaneladdressesturkeyPageCard
              title={"ელ.ფოსტა / E-mail"}
              value={branches.user && branches.user.email}
            />
            <PaneladdressesturkeyPageCard
              title={"ZIP code"}
              value={branches.offices && branches.offices.zip}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaneladdressesPage;
