import { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { AdminPrimaryContext } from "../../../adminContext/AdminContext";
import axiosAdmin from "../../../../../api/apiAdmin";
import { successToast, errorToast } from "../../../toastAlerts/ToastAlerts";

//icons
import {
  BsBoxArrowInRight,
  BsTrash,
  BsFillPersonFill,
  BsFillEnvelopeFill,
  BsTelephoneFill,
  BsFillCalendarFill,
  BsFillHandbagFill,
  BsFillBuildingFill,
  BsArrowReturnLeft,
} from "react-icons/bs";
import { BiPackage } from "react-icons/bi";
import {
  FaBarcode,
  FaMoneyBillAlt,
  FaPlaneDeparture,
  FaScroll,
  FaTruckMoving,
} from "react-icons/fa";
import { GiWeight } from "react-icons/gi";
import ContinueComponent from "../../../../../components/Alerts/ContinueComponent";
import UpdateParcel from "../UpdateParcel";

const SentItem = ({
  data,
  handleDeletePackage,
  setCheckedAll,
  handleSelectedPackages,
  handleDeleteSelectedPackages,
  setDifResponses,
  shelf,
  handleMoveBack,
}) => {
  //package check
  const [check, setCheck] = useState(false);
  //user
  const { user } = useContext(AdminPrimaryContext);
  //show contnue
  const [showContinue, setShowContinue] = useState(false);
  const [showContinue2, setShowContinue2] = useState(false);
  const [showContinue3, setShowContinue3] = useState(false);
  const [showContinue2msg, setShowContinue2msg] = useState(false);
  const [addedShelves, setAddedShelves] = useState([]);
  //is paid
  const [isPaid, setIsPaid] = useState(false);

  //handle checking
  const handleCheck = (e) => {
    setCheck(e.target.checked);
    if (e.target.checked) {
      handleSelectedPackages(data.id);
    } else {
      handleDeleteSelectedPackages(data.id);
    }
  };

  //const handle send package
  const handleSend = async () => {
    if (shelf.x === "" || shelf.y === "") {
      errorToast("გთხოვთ შეავსოთ თაროების ველი!");
    } else {
      try {
        const res = await axiosAdmin.get(
          `/Pack/set_receive_geo/${data.id}/${shelf.x}/${shelf.y}?in_office=${shelf.in_office}`
        );
        if (res) {
          successToast("ამანათი წარმატებით მიიღე!");
          setDifResponses(res);
        }
      } catch (err) {
        if (err.response.status === 300) {
          setShowContinue2(true);
          setAddedShelves(err.response.data.data);
          setShowContinue2msg(`${err.response.data.msg} გსურთ დამატება?`);
        } else if (err.response.status === 402) {
          errorToast("ამანათის ოფისი არ ემთხვევა მონიშნულ ოფისს!");
        } else {
          errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
        }
      }
    }
  };

  //handle send after if package was on shelf
  const handleSendShelf = async (anyway, newShelf, isOk) => {
    try {
      if (isOk === "ok" && newShelf[0] === "" && newShelf[1] === "") {
        errorToast(
          "მონიშნე ახალი თარო და დააწექი ok ან დააწექი anyway რათა ამანათი დაიდოს მითთებულ ადგილას"
        );
      } else {
        const res = await axiosAdmin.get(
          `/Pack/set_receive_geo/${data.id}/` +
            `${newShelf[0] === "" ? shelf.x : newShelf[0]}/${
              newShelf[1] === "" ? shelf.y : newShelf[1]
            }/` +
            `${anyway ? "2" : "1"}?in_office=${shelf.in_office}`
        );
        if (res) {
          successToast("ამანათი წარმატებით მიიღე!");
          setDifResponses(res);
        }
      }
    } catch (err) {
      if (err.response.status === 402) {
        errorToast("ამანათის ოფისი არ ემთხვევა მონიშნულ ოფისს!");
      } else {
        errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
      }
    }
    setShowContinue2(false);
  };

  //make parcel custom clarence
  const handleTurnCustomClarence = async () => {
    try {
      const res = await axiosAdmin.get(`/Pack/notDeclared/${data.id}`);
      if (res) {
        successToast("მოქედება განხორციელდა!");
        setDifResponses(res);
      }
    } catch (err) {
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  //place parcel in yellow corridor
  const handleSendInYellowCorridor = (id) => {
    axiosAdmin
      .get(`/Pack/yvitelia/${id}`)
      .then((res) => {
        successToast("მოქედება განხორციელდა!");
        setDifResponses(res);
      })
      .catch(() => {
        errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
      });
  };

  //check all controller
  useEffect(() => {
    setCheck(setCheckedAll);
    if (setCheckedAll) {
      handleSelectedPackages(data.id);
    } else {
      handleDeleteSelectedPackages(data.id);
    }
  }, [setCheckedAll]);

  //if package is paid
  useEffect(() => {
    if (data.transport_paid !== null) {
      setIsPaid(true);
    }
  }, [data]);

  return (
    <div className="bg-white p-4 rounded-lg relative dark:bg-slate-700">
      {(user.role === 100 ||
        user.role === 1 ||
        user.role === 5 ||
        user.role === 4 ||
        user.role === 8) && (
        <div className="absolute top-5 right-5">
          <input
            onChange={(e) => {
              handleCheck(e);
            }}
            checked={check}
            type="checkbox"
            className="w-4 h-4"
          />
        </div>
      )}
      <div
        className="w-full flex flex-wrap justify-between pb-2
            gap-y-2 border-b border-gray-200 mb-2"
      >
        <div className="w-[32%] md:w-[49%] sm:w-[100%] flex flex-col gap-y-1">
          <div className="w-full">
            <p className="text-[#989ba5] text-[12px]">
              მომხმარებელის ინფორმაცია
            </p>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsFillPersonFill className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              {data.user !== null && data.user.user_type === 1 && (
                <p className="text-slate-500 dark:text-gray-400 text-[12px] font-bold">
                  {`${data.user.fname} ${data.user.lname}`} <br />
                  {`${data.user.fname_eng} ${data.user.lname_eng}`}
                </p>
              )}
              {data.user !== null && data.user.user_type === 2 && (
                <p className="text-slate-500 dark:text-gray-400 text-[12px] font-bold">
                  {`${data.user.ceo_fname} ${data.user.ceo_lname}`} <br />
                  {`${data.user.ceo_fname_eng} ${data.user.ceo_lname_eng}`}
                </p>
              )}
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaBarcode className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">{`id: ${
                data.user !== null && data.user.id
              }`}</p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsFillEnvelopeFill className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                {data.user !== null && data.user.email}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsTelephoneFill className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                {data.user !== null && data.user.phone}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsFillBuildingFill className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              {data.abroad_office !== null && (
                <p
                  className={`text-[14px] cursor-pointer font-bold
                                ${
                                  data.abroad_office.titleen ===
                                  "Vazha Pshavela branch N2"
                                    ? "text-yellow-500"
                                    : "text-purple-500"
                                }
                                `}
                >
                  ოფისი: {data.abroad_office.titleen}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="w-[32%] md:w-[49%] sm:w-[100%] flex flex-col gap-y-1">
          <div className="w-full">
            <p className="text-[#989ba5] text-[12px]">გზავნილის ინფორმაცია</p>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BiPackage className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                გზავნილის ID: {data.tracking_number}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaMoneyBillAlt className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                დეკლ: თანხის ჯამი{" "}
                {data.total_price && data.total_price.toFixed(2)}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <GiWeight className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                წონა real: {data.real_weight}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <GiWeight className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                წონა volume: {data.volume_weight} {"("} w:
                {JSON.parse(data.dementions)?.x} h:
                {JSON.parse(data.dementions)?.y} l:
                {JSON.parse(data.dementions)?.z}
                {")"}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <GiWeight className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                წონა : {data.total_weight}{" "}
                {data.real_weight === 0 && data.volume_weight === 0
                  ? "გაურკვეველია"
                  : data.real_weight < data.volume_weight
                  ? "volume"
                  : "real"}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsFillCalendarFill className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                თარიღი: {data.create_date}
              </p>
            </div>
          </div>
        </div>
        <div className="w-[32%] md:w-[49%] sm:w-[100%] flex flex-col gap-y-1">
          <div className="w-full">
            <p className="text-[#989ba5] text-[12px]">გზავნილის ინფორმაცია</p>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaPlaneDeparture className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p
                className={`text-[14px] text-slate-500 dark:text-gray-400 cursor-pointer font-bold`}
              >
                რეისი: {data.flight_number}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaScroll className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p
                className={`text-[14px] ${
                  data.addon_transportation ? "text-green-500" : "text-red-500"
                } `}
              >
                {data.addon_transportation ? "დაზღვეულია" : "არაა დაზღვეული"}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaTruckMoving className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p
                className={`text-[14px] ${
                  data.transport_address ? "text-green-500" : "text-red-500"
                } `}
              >
                საკურიერო:{" "}
                {data.transport_address ? data.transport_address : "X"}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaScroll className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              {data.invoice !== "" ? (
                <Link
                  to={`${process.env.REACT_APP_API_URL}/storage/uploads/pack_files/${data.invoice}`}
                  target={"_blank"}
                >
                  <p
                    className={`text-[14px] text-violet-500 underline cursor-pointer`}
                  >
                    ინვოისი
                  </p>
                </Link>
              ) : (
                <p
                  className={`text-[14px] text-red-500 underline cursor-pointer`}
                >
                  ინვოისი
                </p>
              )}
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsFillHandbagFill className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p
                className={`text-[14px] text-slate-500 dark:text-gray-400 cursor-pointer font-bold`}
              >
                ჩანთა: {data.bag}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-2 w-full justify-between flex-col">
        <div className="flex flex-col gap-y-1">
          <p className="text-[#989ba5] text-[12px]">კომენტარი:</p>
          <p className="text-slate-500 dark:text-gray-400 text-[14px]">
            {data.comment}
          </p>
        </div>
        <div className="grid grid-cols-4 gap-4 md:grid-cols-2 sm:grid-cols-1">
          {user.role !== 6 &&
            (user.role !== 4 && user.role !== 5 && data.gansabaj === 0 ? (
              <div
                onClick={() => {
                  setShowContinue3(true);
                }}
                className="rounded-lg bg-red-400 flex items-center justify-center gap-2 px-4 h-10 w-full cursor-pointer"
              >
                <p className="text-white font-bold">
                  გადაიტანე განსაბაჟებლებში
                </p>
              </div>
            ) : data.gansabaj === 2 ? (
              <div className="w-full justify-center items-center flex">
                <p className="text-green-400 font-bold">განბაჟებულია</p>
              </div>
            ) : data.gansabaj !== 0 ? (
              <div className="w-full justify-center items-center flex">
                <p className="text-red-400 font-bold">განსაბაჟებელია</p>
              </div>
            ) : (
              <></>
            ))}

          {user.role !== 6 && (
            <>
              {data.yviteli === 0 && (
                <div
                  onClick={() => {
                    handleSendInYellowCorridor(data.id);
                  }}
                  className="rounded-lg bg-yellow-400 flex items-center justify-center gap-2 px-4 h-10 w-full cursor-pointer"
                >
                  <p className="text-white font-bold">ყვითელი კორიდორი</p>
                </div>
              )}
              {data.yviteli === 1 && (
                <div className="rounded-lg border border-slate-300 flex items-center justify-center gap-2 px-4 h-10 w-full">
                  <p className="font-bold text-green-500">ყვითელ დერეფანშია</p>
                </div>
              )}
              {data.yviteli === 2 && (
                <div className="rounded-lg border border-slate-300 flex items-center justify-center gap-2 px-4 h-10 w-full">
                  <p className="font-bold text-green-500">
                    გამწვანდა არაფერი არაა საჭირო
                  </p>
                </div>
              )}
              {data.yviteli === 3 && (
                <div className="rounded-lg border border-slate-300 flex items-center justify-center gap-2 px-4 h-10 w-full">
                  <p className="font-bold text-green-500">
                    ყვითელიდან გადავიდა განსაბაჟებელში
                  </p>
                </div>
              )}
            </>
          )}

          {user.role !== 6 && (
            <div
              onClick={handleSend}
              className="rounded-lg bg-orange-400  flex items-center justify-center gap-2 px-4 h-10 w-full cursor-pointer"
            >
              <BsBoxArrowInRight className="text-white font-bold" />
              <p className="text-white font-bold">მიღება</p>
            </div>
          )}
          {(user.role === 100 || user.role === 1) && (
            <div
              onClick={() => {
                setShowContinue(true);
              }}
              className="w-full h-10 rounded-lg bg-red-400 flex items-center justify-center cursor-pointer"
            >
              <BsTrash className="text-white font-bold" />
              <p className="text-white font-bold">წაშლა</p>
            </div>
          )}
          {(user.role === 100 || (user.role === 1 && !isPaid)) && (
            <div className="flex justify-end gap-1 ">
              <div
                onClick={() => {
                  handleMoveBack(data.id);
                }}
                className="h-10 rounded-lg bg-green-400 flex items-center justify-center cursor-pointer gap-2 px-4 w-full"
              >
                <BsArrowReturnLeft className="text-white font-bold" />
                <p className="text-white text-md font-bold">უკან</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <UpdateParcel
        dataUpdater={setDifResponses}
        tracking={data.tracking_number}
      />
      <ContinueComponent
        showFlag={showContinue2}
        actionFunction={handleSendShelf}
        showContinuemsg={showContinue2msg}
        setShowContinue={setShowContinue2}
        needAnyway={true}
        addedShelvess={addedShelves}
      />
      <ContinueComponent
        showFlag={showContinue}
        actionFunction={handleDeletePackage}
        id={data.id}
        setShowContinue={setShowContinue}
      />
      <ContinueComponent
        showFlag={showContinue3}
        actionFunction={handleTurnCustomClarence}
        setShowContinue={setShowContinue3}
      />
    </div>
  );
};
export default SentItem;
