const categoryOptions = [
  {
    name: "ქალებისთვის",
    value: 1,
  },
  {
    name: "მამაკაცებისთვის",
    value: 2,
  },
  {
    name: "ბავშვებისთვის",
    value: 3,
  },
  {
    name: "თავის მოვლის საშუალებები",
    value: 5,
  },
  {
    name: "საყოფაცხოვრებო",
    value: 6,
  },
  {
    name: "ავტო ნაწილები",
    value: 7,
  },
  {
    name: "ბრენდ მაღაზიები",
    value: 9,
  },
  {
    name: "სხვა",
    value: 10,
  },
  {
    name: "ჩინეთის მაღაზიები",
    value: 11,
  },
  {
    name: "ავეჯი",
    value: 12,
  }
];

export default categoryOptions;
